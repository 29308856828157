.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');

:root {
  --primary-white: #ffffff;
  --primary-light-grey: #f4f4f4;
  --primary-grey: #ccd1d9;
  --primary-dark-grey: #666666;
  --primary-orange: #f9ab00;
  --primary-dark: rgb(52, 58, 64);
  --primary-red: #ef4035;
  --primary-hover-red: #f33501;
  --primary-black: #000000;
}

body {
  font-family: "Roboto Condensed", sans-serif !important;
}

/************************************* NAVBAR *********************************************/
.logo {
  margin-top: 15px;
  width: 16.25rem;
  cursor: pointer;
}

/*---------------- MENU LINKS ---------------------*/
nav a.nav-link {
  font-weight: 500;
  letter-spacing: 0.0375rem;
  cursor: pointer;
}

nav ul li {
  text-transform: uppercase;
}

.navbar-nav > li > a {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  color: var(--primary-white) !important;
  border-top: 0.1875rem solid var(--primary-dark);
}

.navbar-brand {
  max-width: 17.25rem;
  padding: 0 !important;
  height: 6rem !important;
}

@media(max-width: 768px) {
  .navbar-brand {
    width: 7rem;
  }
}

.navbar-nav > li > a.active,
.navbar-nav>li>a:focus {
  border-top: 0.1875rem solid var(--primary-orange);
}

.navbar-nav>li>a:hover {
  border-top: 0.1875rem solid var(--primary-orange);
}

.hamburger-menu {
  color: var(--primary-white);
}

/************************************* HEADER *********************************************/
.header-wrapper {
  position: relative;
  background: url(./images/background.jpg);
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.main-info {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1;
}

.main-info h1 {
  color: var(--primary-orange);
  text-transform: uppercase;
}

/*---------------- TYPED TEXT -----------------------------*/
.typed-text {
  font-size: 2rem;
  color: var(--primary-white);
}

@media(max-width: 768px) {
  .main-info h1 {
    font-size: 1.7rem !important;
  }

  .typed-text {
    font-size: 1.3rem;
  }
}

/*---------------- HEADER MAIN OFFER ---------------------*/
.btn-main-offer {
  border: 1px solid var(--primary-red);
  text-transform: uppercase;
  border-radius: 0;
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  color: var(--primary-white);
  margin: 2rem;
}

.btn-main-offer:hover {
  text-decoration: none;
  background-color: var(--primary-hover-red);
  color: var(--primary-white);
  transition: .2s ease-in-out;
}

/*---------------- IMAGE ATTRIBUTION ---------------------*/
.image-att {
  font-size: 1rem;
  color: var(--primary-white);
  text-decoration: none;
}

/************************************* ABOUT COMPONENTS *********************************************/
.photo-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  }

.profile-img {
  width: 16rem;
  border-radius: 50%;
  padding: .3rem;
  border: 1px solid var(--primary-orange);
}

.about-heading {
  color: var(--primary-orange);
  text-transform: uppercase;
}

@media(max-width: 468px) {
  .profile-img {
    width: 10rem;
  }
}

/************************************* PORTFOLIO COMPONENTS *********************************************/
.portfolio-wrapper {
  background: var(--primary-light-grey);
  padding: 3rem 0;
}

.portfolio-wrapper h1 {
  color: var(--primary-orange);
}

.portfolio-image {
  width: 15rem;
  border: 1px solid var(--primary-orange);
  padding: 0 0.5rem;
}

.portfolio-image-box {
  position: relative;
  margin: 1rem;
}

/*---------------- OVERLOW BOX ---------------------*/
.overflow {
  position: absolute;
  opacity: 0;
  background: var(--primary-black);
  width: 15rem;
  height: 8rem;
  top: 0;
  border: 1px solid var(--primary-red);
  cursor: pointer;
}

.portfolio-image-box:hover .overflow {
  opacity: 60%;
  transition: 0.3s ease-in-out;
}

.portfolio-icon {
  position:absolute;
  opacity: 0;
  margin-left: -8rem;
  margin-top: 3.6rem;
  color: var(--primary-orange);
  font-size: 1.5rem;
}

.portfolio-image-box:hover .portfolio-icon {
  opacity: 70%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

/*---------------- POPUP BOX ---------------------*/
.portfolio-image-popupbox {
  width: 45rem;
  padding: 0.5rem;  
}

.hyper-link {
  cursor: pointer;
  color: var(--primary-orange)
}

.hyper-link:hover {
  color: var(--primary-red)
}

/*---------------- MEDIA ---------------------*/
@media(max-width: 768px) {
  .portfolio-image-popupbox {
    width: 100%;
  }  
}

@media(max-height: 500px) {
  .popupbox-wrapper {
    height: 100% !important;
  }
  
  .portfolio-image-popupbox {
    width: 50%;
  }  
}

@media(max-height: 280px) {
  .popupbox-wrapper {
    height: 90% !important;
  }
  
  .portfolio-image-popupbox {
    width: 50%;
  }  
}

/************************************* CONTACT COMPONENTS *********************************************/
.contact {
  background: var(--primary-dark);
  padding: 3rem 0;
}

.contact h1 {
  color: var(--primary-orange);
  padding: 1.3rem 0;
  text-transform: uppercase;
}

.contact p {
  color: var(--primary-light-grey);
  font-size: 1.2rem;
}

/*---------------- INPUT ---------------------*/
.contact input, .contact textarea {
  width: 100%;
  box-sizing: border-box;
  background: none;
  border: 0;
  border-bottom: 0.125rem solid var(--primary-dark-grey);
  border-radius: 0;
  color: var(--primary-orange);
  outline: 0 !important;
  box-shadow: none !important;
  margin-bottom: 1rem;
  padding-left: 0 !important;
}

.contact .container {
  padding: 4rem 0;
}

.contact input:focus, .contact textarea:focus {
  background: transparent;
  color: var(--primary-orange);
  border-bottom: 0.125rem solid var(--primary-dark-grey)
}

.contact textarea {
  height: 12.2rem !important;
}

.contact-btn {
  margin: 1rem 0 0 0;
  width: 100%;
  background: none;
}

.line {
  position: relative;
  top: -0.8rem;
  width: 0;
  height: 2px;
  background: var(--primary-orange);
  display: inline-block;
}

input:focus+.line, textarea:focus+.line {
  width: 100%;
  transition: 0.5s ease-in-out;
}

input.form-control {
  margin: -0.3rem;
}

textarea.form-control {
  margin: -0.3rem;
}

/*---------------- MEDIA ---------------------*/
@media(max-width: 768px) {
 .contact {
  overflow-x: hidden;
 }
 
  .contact .container {
    padding: 0 1rem;
  }

  .contact p {
    padding: 0 0.4rem;
  }
}

/************************************* FOOTER COMPONENTS *********************************************/
.footer {
  background: var(--primary-black);
  color: var(--primary-dark-grey);
  padding: 3rem;
}

.footer a {
  text-decoration: none;
  color: var(--primary-dark-grey);
}

.footer a:hover {
  text-decoration: none;
  color: var(--primary-orange);
  cursor: pointer;
}

.footer p {
  margin-bottom: 0;
}